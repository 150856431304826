import axios from 'axios'
import globals from '@/config/globals'

export default {
  index() {
    return axios.get(`${globals.getApiHost()}/rater/templates`)
  },
  get({ templateId }) {
    return axios.get(`${globals.getApiHost()}/rater/templates/${templateId}`)
  },
  store(data) {
    return axios.post(`${globals.getApiHost()}/rater/templates`, data)
  },
  update({ templateId, data }) {
    return axios.put(`${globals.getApiHost()}/rater/templates/${templateId}`, data)
  },
  clone({ templateId }) {
    return axios.post(`${globals.getApiHost()}/rater/templates/${templateId}/clone`)
  },
  delete({ templateId }) {
    return axios.delete(`${globals.getApiHost()}/rater/templates/${templateId}`)
  },
  getQuestionsAvailable({ lob }) {
    return axios.get(`${globals.getApiHost()}/rater/templates/questions-available/${lob}`)
  },
  getTypesAvailable() {
    return axios.get(`${globals.getApiHost()}/rater/templates/types-available`)
  },
  getNextIndex() {
    return axios.get(`${globals.getApiHost()}/rater/templates/next-index`)
  }
}
