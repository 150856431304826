import globals from '@/config/globals'
import axios from 'axios'

export default {
  show({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period`)
  },
  summary({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/summary`)
  },
  completeStep({ date, step }) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/complete-step`, {
      step: step,
    })
  },
  finalize({ date }) {
    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.post(`${globals.getApiHost()}/commissions/statements/${year}/${month}/finalize`)
  },
  export({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/export`)
  },
  exportDetailed({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/pay-period/export-detailed`)
  },
}
