let debug = require('debug');
import Vue from 'vue';
import _ from 'lodash';

export default {
  init() {
    let enableDebug = (
      process.env.VUE_APP_ENVIRONMENT === 'local' ||
      process.env.VUE_APP_CONSOLE_OVERRIDE === 'true' ||
      process.env.VUE_APP_CONSOLE_OVERRIDE === true);

    let debugChannels = [
      {name: 'auth', type: 'info'},
      {name: 'requests', type: 'log'},
      {name: 'info', type: 'info'},
      {name: 'errors', type: 'error'},
    ];

    Vue.prototype.$debug = {};

    _.forEach(debugChannels, (value) => {
      Vue.prototype.$debug[value.name] = debug('debug:' + value.name);
      if (enableDebug) {
        Vue.prototype.$debug[value.name].log = console[value.type].bind(console);
      }
    });

    if (enableDebug) {
      debug.enable('debug:*');
    }
  }
}
