import { mdiAccount, mdiCash, mdiCogOutline, mdiFormatListGroup } from '@mdi/js'

let date = new Date()

export default [
  /*
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  */
  {
    title: 'All Commissions',
    icon: mdiCash,
    to: {
      name: 'commission-overview.entry',
      params: {
        year: date.getFullYear(),
        month: ('0' + (date.getMonth() + 1)).slice(-2),
      },
    },
  },
  {
    title: 'Start Review',
    icon: mdiCash,
    to: {
      name: 'commission-statement.entry',
      params: {
        month: ('0' + (date.getMonth() + 1)).slice(-2),
        year: date.getFullYear(),
      },
    },
  },
  {
    title: 'Edit Commission Plans',
    icon: mdiFormatListGroup,
    to: 'commission-settings',
  },
  {
    title: 'Rater Configuration',
    icon: mdiCogOutline,
    to: 'rater-configuration',
    when(account) {
      return account.attributes.permissions['rater-configuration'] === true
    },
  },
  {
    title: 'Rater Applications',
    icon: mdiAccount,
    to: 'rater-applications',
    when(account) {
      return account.attributes.permissions['rater-applications'] === true
    },
  },
  {
    title: 'Rater Templates',
    icon: mdiAccount,
    to: 'rater-templates',
    when(account) {
      return account.attributes.permissions['rater-configuration'] === true
    },
  },
]
