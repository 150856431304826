import api from '@/api'
import utilities from '@/config/globals/utilities'
import _ from 'lodash'
import moment from 'moment/moment'

export default {
  namespaced: true,
  state: {
    activeUser: null,
    activeDate: null,

    commissions: [],
    commissionsTable: [],
    plans: [],
    user: {},

    bonusesAndFees: [],
    bonusesAndFeesTotal: 0,

    base_commission_amount: 0,

    approved: false,

    subtotal: 0,
    total: 0,

    commissionLineErrors: [],

    sort: undefined,
    sortDesc: undefined
  },
  getters: {
    getCommissionLineErrors: state => {
      return state.commissionLineErrors
    },
    getActiveUser: state => {
      return state.activeUser
    },
    getActiveDate: state => {
      return state.activeDate
    },
    getCommissions: state => {
      return state.commissions
    },
    getCommissionsTable: state => {
      return state.commissionsTable
    },
    getPlans: state => {
      return state.plans
    },
    getBonusesAndFees: state => {
      return state.bonusesAndFees
    },
    getBonusesAndFeesTotal: state => {
      return state.bonusesAndFeesTotal
    },
    getTotal: state => {
      return state.total
    },
    getSubtotal: state => {
      return state.subtotal
    },
    getApproved: state => {
      return state.approved
    },
    getUser: state => {
      return state.user
    },
    getBaseCommissionAmount: state => {
      return state.base_commission_amount
    },
    getSort: state => {
      return state.sort
    },
    getSortDesc: state => {
      return state.sortDesc
    }
  },
  mutations: {
    setSort(state, sort) {
      state.sort = sort
    },
    setSortDesc(state, sortDesc) {
      state.sortDesc = sortDesc
    },
    setAvailablePlans(state, plans) {
      state.plans = plans
    },
    setStatement(state, statement) {
      state.activeUser = statement.user.id
      state.activeDate = statement.date
      //state.plans = statement.plans //moved this to its own api endpoint to populate
      state.approved = statement.approved
      state.user = statement.user
      state.commissions = statement.commissions

      this.commit('commissions/producerStatement/setCommissionTotals', statement)

      this.commit('commissions/producerStatement/formatDataForTable')
    },
    setStatementAfterBonusUpdate(state, statement) {
      state.total = statement.total.toFixed(2)
      //state.bonusesAndFees = statement.bonuses
      state.bonusesAndFeesTotal = statement.bonuses_total === null ? 0 : statement.bonuses_total.toFixed(2)
    },
    setCommissionTotals(state, statement) {
      state.total = statement.total.toFixed(2)
      state.subtotal = statement.commissions_total.toFixed(2)
      state.bonusesAndFees = statement.bonuses
      state.bonusesAndFeesTotal = statement.bonuses_total === null ? 0 : statement.bonuses_total.toFixed(2)

      state.base_commission_amount = _.sumBy(statement.commissions, commission => {
        return parseFloat(commission.attributes.base_amount)
      })
    },
    setCommission(state, commission) {
      let index = _.findIndex(state.commissions, { id: commission.id })

      let newCommission = { ...state.commissions[index], ...commission }

      state.commissions.splice(index, 1, newCommission)
      this.commit('commissions/producerStatement/formatDataForTable')

      /**
       * TODO:: need to update totals and bonus values
       * have an Action that will pull statement, but ONLY update those
       */
    },
    setCommissionPay(state, { commissionId, pay }) {
      let index = _.findIndex(state.commissions, { id: commissionId })

      let commissionObject = _.cloneDeep(state.commissions[index])
      commissionObject.attributes.user_split_amount = pay
      commissionObject.attributes.user_split_overwritten = true

      this.commit('commissions/producerStatement/setCommission', commissionObject)
    },
    setCommissionLineErrors(state, { errors }) {
      state.commissionLineErrors = errors
    },
    formatDataForTable(state) {
      let commissions = _.sortBy(state.commissions, commission => {
        return moment(commission.attributes.effective_date)
      })

      state.commissionsTable = _.map(commissions, commission => {
        return {
          id: commission.id,
          effective_date: moment(commission.attributes.effective_date).format('MMM Do'),
          type: _.startCase(commission.attributes.reason.code),
          policy: commission.attributes.policy_number,
          carrier: commission.attributes.carrier,
          lob: _.startCase(commission.attributes.policy_type),
          terms: commission.attributes.terms,
          sold_date: (() => {
            let won_date = commission.related?.policy?.data?.attributes?.won_date

            if (won_date && won_date.length) {
              return moment(won_date).format('MMM Do')
            }

            return null
          })(),
          final_amount:utilities.formatCurrency(commission.attributes.final_amount)||0,
          agency_commissions: utilities.formatCurrency(commission.attributes.base_amount)||0,
          agency_commissions_raw: utilities.formatRawValue(commission.attributes.base_amount)||0,
          agent_pay_rate: (() => {
            let value = (((commission.attributes.user_split_amount * 100) / commission.attributes.base_amount).toFixed(2) || 0);

            if(!isFinite(value) || isNaN(value)){
              value = parseFloat(0).toFixed(2)
            }

            return value + '%';
          })(),
          agent_pay_rate_raw: (() => {
            let value = 0
            if (utilities.formatRawValue(commission.attributes.user_split_amount) === 0) {
              value = parseFloat(0).toFixed(2) || 0
            } else {
              value = (((commission.attributes.user_split_amount * 100) / commission.attributes.base_amount).toFixed(2)) || 0
            }

            if(!isFinite(value) || isNaN(value)){
              value = parseFloat(0).toFixed(2)
            }

            return value
          })(),
          agent_pay: utilities.formatCurrency(commission.attributes.user_split_amount)||0,
          agent_pay_raw: utilities.formatRawValue(commission.attributes.user_split_amount)||0,
          premium: utilities.formatCurrency(commission.attributes.premium)||0,
          is_negative: commission.attributes.premium < 0,
          plan: commission.related.plan.data,
          plans: state.plans,
          insured: commission.attributes.insured_name,
          user_split_overwritten: commission.attributes.user_split_overwritten,
          aggregator_cut:utilities.formatCurrency(commission.attributes.aggregator_cut)||0,
          user_id: state.user.id,
          errors: [],
          is_manual: commission.attributes.manually_added,
          ivans_status: commission.attributes.ivans_status,
          lead_source: commission.related?.lead_source?.data?.attributes?.name,
          original: (() => {
            if (commission.related.original_plan === null) {
              return false
            }

            let code = commission.attributes.reason.code
            let percent = false

            if (code === 'NBS') {
              percent = commission.related.original_plan?.data?.attributes?.new_percentage
            } else if (code === 'RWL') {
              percent = commission.related.original_plan?.data?.attributes?.renewal_percentage
            }

            if (!percent) {
              return false
            }

            return {
              percent: percent,
              value: (commission.attributes.base_amount * (percent / 100)).toFixed(2),
            }
          })(),
        }
      })
    },
  },
  actions: {
    setSort(state, sort) {
      state.commit('setSort', sort)
    },
    setSortDesc(state, sortDesc) {
      state.commit('setSortDesc', sortDesc)
    },
    clearSort(state) {
      state.commit('setSort', undefined)
      state.commit('setSortDesc', undefined)
    },
    setStatement({ commit }, statement) {
      commit('setStatement', statement)
    },
    setCommission({ commit }, commission) {
      commit('setCommission', commission)
    },
    setCommissionPay({ commit }, { commissionId, pay }) {
      commit('setCommissionPay', { commissionId, pay })
    },
    setAvailablePlans({ commit }, plans) {
      commit('setAvailablePlans', plans)
    },
    async loadStatement({ commit }, { producerId, date }) {
      return new Promise((resolve, reject) => {
        return api.commissionStatements
          .user({
            user: producerId,
            date: date,
          })
          .then(({ data }) => {
            commit('setStatement', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async refreshStatement({ commit, state }) {
      return new Promise((resolve, reject) => {
        return api.commissionStatements
          .user({
            user: state.user.id,
            date: state.activeDate,
          })
          .then(({ data }) => {
            commit('setStatement', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async updateBonusesAndFees({ commit, state }, { bonusesAndFees }) {
      return new Promise((resolve, reject) => {
        return api.commissionStatements
          .saveBonuses({
            user: state.activeUser,
            date: state.activeDate,
            bonuses: bonusesAndFees,
          })
          .then(({ data }) => {
            commit('setStatementAfterBonusUpdate', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCommissionPlan({ commit, state, dispatch }, { commissionId, planId }) {
      return api.commissions
        .updatePlan({
          commission: commissionId,
          plan: planId,
        })
        .then(({ data }) => {
          commit('setCommission', data)
          dispatch('refreshStatementTotals')
        })
    },
    async refreshStatementTotals({ commit, state }, statement = false) {
      return new Promise((resolve, reject) => {
        if (statement) {
          commit('setCommissionTotals', statement)
          return
        }

        return api.commissionStatements
          .user({
            user: state.activeUser,
            date: state.activeDate,
          })
          .then(({ data }) => {
            commit('setCommissionTotals', data)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async approveStatement({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        return api.commissionStatements
          .approve({
            user: state.activeUser,
            date: state.activeDate,
          })
          .then(({ data }) => {
            commit('setStatement', data)
            dispatch('commissions/producers/refreshProducers', {}, { root: true }).then(() => {
              resolve(data)
            })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setCommissionLineErrors({ commit }, { errors }) {
      commit('setCommissionLineErrors', { errors })
    },
  },
}
