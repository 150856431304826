import api from '@/api'
import utilities from '@/config/globals/utilities'
import _ from 'lodash'
import moment from 'moment/moment'

export default {
  namespaced: true,
  state: {
    activeDate: null,
    commissions: [],
    commissionsTable: [],
    meta: {},
    assignments: {},
    sort: undefined,
    sortDesc: undefined
  },
  getters: {
    getCommissions: state => state.commissions,
    getCommissionsTable: state => state.commissionsTable,
    getAssignments: state => state.assignments,
  },
  mutations: {
    setSort(state, sort) {
      state.sort = sort
    },
    setSortDesc(state, sortDesc) {
      state.sortDesc = sortDesc
    },
    setActiveDate(state, date) {
      state.activeDate = date
    },
    setCommissions(state, commissions) {
      state.commissions = commissions

      let assignments = {}
      _.forEach(commissions, commission => {
        assignments[commission.id] = null
      })

      state.assignments = assignments

      this.commit('commissions/unmatched/formatDataForTable')
    },
    setCommission(state, commission) {
      state.commissions = state.commissions.map(c => {
        if (c.id === commission.id) {
          return commission
        }
        return c
      })
    },

    setAssignment(state, { commissionId, assignment }) {
      let assignments = _.cloneDeep(state.assignments)
      assignments[commissionId] = assignment

      state.assignments = assignments
    },

    setMeta(state, meta) {
      state.meta = meta
    },
    formatDataForTable(state) {
      state.commissionsTable = _.map(state.commissions, commission => {
        return {
          id: commission.id,
          effective_date: moment(commission.attributes.effective_date).format('MMM Do'),
          type: _.startCase(commission.attributes.reason.code),
          policy: commission.attributes.policy_number,
          carrier: commission.attributes.carrier,
          lob: _.startCase(commission.attributes.policy_type),
          terms: commission.attributes.terms,
          sold_date: (() => {
            let won_date = commission.related?.policy?.data?.attributes?.won_date

            if (won_date && won_date.length) {
              return moment(won_date).format('MMM Do')
            }

            return null
          })(),
          agency_commissions: utilities.formatCurrency(commission.attributes.final_amount),
          agency_commissions_raw: utilities.formatRawValue(commission.attributes.final_amount),
          agent_pay_rate:
            ((commission.attributes.user_split_amount * 100) / commission.attributes.final_amount).toFixed(2) + '%',
          agent_pay_rate_raw: (() => {
            let value = 0
            if (utilities.formatRawValue(commission.attributes.user_split_amount) === 0) {
              value = parseFloat(0).toFixed(2)
            } else {
              value = ((commission.attributes.user_split_amount * 100) / commission.attributes.final_amount).toFixed(2)
            }

            if (isNaN(value)) {
              value = parseFloat(0).toFixed(2)
            }

            return value
          })(),
          final_amount:utilities.formatCurrency(commission.attributes.final_amount)||0,
          agent_pay: utilities.formatCurrency(commission.attributes.user_split_amount),
          agent_pay_raw: utilities.formatRawValue(commission.attributes.user_split_amount),
          premium: utilities.formatCurrency(commission.attributes.premium),
          is_negative: commission.attributes.premium < 0,
          plan: commission.related.plan.data,
          plans: state.plans,
          insured: commission.attributes.insured_name,
          user_split_overwritten: commission.attributes.user_split_overwritten,
          aggregator_cut:utilities.formatCurrency(commission.attributes.aggregator_cut)||0,
          user_id: null,
          errors: [],
          is_manual: commission.attributes.manually_added,
          ivans_status: commission.attributes.ivans_status,
          original: (() => {
            if (commission.related.original_plan === null) {
              return false
            }

            let code = commission.attributes.reason.code
            let percent = false

            if (code === 'NBS') {
              percent = commission.related.original_plan?.data?.attributes?.new_percentage
            } else if (code === 'RWL') {
              percent = commission.related.original_plan?.data?.attributes?.renewal_percentage
            }

            if (!percent) {
              return false
            }

            return {
              percent: percent,
              value: (commission.attributes.final_amount * (percent / 100)).toFixed(2),
            }
          })(),
        }
      })
    },
  },
  actions: {
    setSort(state, sort) {
      state.commit('setSort', sort)
    },
    setSortDesc(state, sortDesc) {
      state.commit('setSortDesc', sortDesc)
    },
    clearSort(state) {
      state.commit('setSort', undefined)
      state.commit('setSortDesc', undefined)
    },
    async loadUnmatchedCommissions({ commit, dispatch, state }, { date }) {
      commit('setActiveDate', date)

      return new Promise((resolve, reject) => {
        return api.commissions
          .unmatched({
            filters: {
              date: date,
            },
          })
          .then(({ data, meta }) => {
            commit('setCommissions', data)
            commit('setMeta', meta)
            resolve({ data, meta })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async refreshUnmatchedCommissions({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        return api.commissions
          .unmatched({
            filters: {
              date: state.activeDate,
            },
          })
          .then(({ data, meta }) => {
            commit('setCommissions', data)
            commit('setMeta', meta)
            resolve({ data, meta })
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    setAssignment({ commit, dispatch, state }, { commissionId, assignment }) {
      commit('setAssignment', { commissionId, assignment })
    },
    async updateAssignments({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        return api.commissions
          .bulkAssign({ assignments: state.assignments })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
