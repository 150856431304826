import axios from 'axios'
import globals from '@/config/globals'
import qs from 'qs'

export default {
  details({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/details`)
  },
  loadAggregatorPercentage({ date }) {
    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/monthly-aggregator-percentage`)
  },
  updateAggregatorPercentage({
                               date,
                               aggregator_rate_type,
                               aggregator_percentage,
                               aggregator_flat_rate,
                             }) {
    return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/monthly-aggregator-percentage`, {
      aggregator_rate_type: aggregator_rate_type,
      aggregator_percentage: aggregator_percentage,
      aggregator_flat_rate: aggregator_flat_rate,
    })
  },
  availablePlans({ user, date }) {
    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.get(`${globals.getApiHost()}/commissions/statements/${year}/${month}/user/${user}/available-plans`)
  },
  deferredCommissions({user, date}){

    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.get(`${globals.getApiHost()}/commissions/statements/${year}/${month}/user/${user}/deferred-commissions`)
  },
  user({ user, date }) {
    //return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`);

    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.get(`${globals.getApiHost()}/commissions/statements/${year}/${month}/user/${user}`)
  },
  users({ page = 0, date }) {
    let query = {
      page: page,
      page_size: 20,
    }

    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    //axios.get(`${globals.getApiHost()}/commissions-statements/${date}/users?` + qs.stringify(query));
    return axios.get(`${globals.getApiHost()}/commissions/statements/${year}/${month}/users?` + qs.stringify(query))
  },
  unmatched({ page = 0, date }) {
    let query = {
      page: page,
    }

    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}?` + qs.stringify(query))
  },
  export({ user, date, sort, sortDesc }) {
    let query = {
      sort: sort,
      sortDesc: sortDesc,
    }

    return axios.get(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}/export?` + qs.stringify(query))
  },
  saveBonuses({ user, date, bonuses }) {
    // return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`, {
    //   bonuses: bonuses,
    // })

    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.put(`${globals.getApiHost()}/commissions/statements/${year}/${month}/user/${user}`, {
      bonuses: bonuses,
    })
  },
  approve({ user, date }) {
    // return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`, {
    //   approve: true,
    // })

    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.post(`${globals.getApiHost()}/commissions/statements/${year}/${month}/user/${user}/approve`)
  },
  unapprove({ user, date }) {
    // return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/details/${user}`, {
    //   clearApproval: true,
    // })

    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.delete(`${globals.getApiHost()}/commissions/statements/${year}/${month}/user/${user}/approve`)
  },
  finalize({ date }) {
    //return axios.put(`${globals.getApiHost()}/commissions-statements/${date}/finalize`)
    let parts = date.split('-')
    let year = parts[0]
    let month = parts[1]

    return axios.post(`${globals.getApiHost()}/commissions/statements/${year}/${month}/finalize`)
  },
}
