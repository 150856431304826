const routes = [
  {
    path: '/rater-configuration',
    name: 'rater-configuration',
    component: () => import('@/pages/rater/configuration/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/rater',
    name: 'rater-applications',
    component: () => import('@/pages/rater/applications/Index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path:'/rater-templates',
    name:'rater-templates',
    component: () => import('@/pages/rater/templates/Index.vue'),
    meta: {
      layout: 'content',
      legacyRoute: '/rater/templates',
    }
  },
  {
    path:'/rater-templates/create',
    name:'rater-templates-create',
    component: () => import('@/pages/rater/templates/Create.vue'),
    meta: {
      layout: 'content',
      legacyRoute: '/rater/templates/create',
    }
  },{
    path:'/rater-templates/:template',
    name:'rater-templates-edit',
    component: () => import('@/pages/rater/templates/Edit.vue'),
    meta: {
      layout: 'content',
      legacyRoute: '/rater/templates/:template',
    }
  }
];

export default routes;
